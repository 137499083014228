@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

:root {
  /* --max-width: 1100px; */
  --border-radius: 12px;

  // Font family
  --primary-font-family: "Hanken Grotesk", sans-serif;
  --secondary-font-family: "Poppins", sans-serif;

  /* Background color */
  --background-rgb: 254, 245, 237;
  --foreground-rgb: 255, 255, 255;

  /* Button/text colors */
  --primary-color-rgb: 255, 167, 38;
  --secondary-color-rgb: 0, 0, 0;

  // Text color
  --text-primary-color-rgb: 0, 0, 0;

  /* Supporting colors */
  --green-color-rgb: 49, 103, 95;

  // Font family
  .cl_primary_font {
    font-family: var(--primary-font-family);
  }
  .cl_secondary_font {
    font-family: var(--secondary-font-family);
  }

  // Text colors
  .cl_primary_color {
    color: rgb(var(--primary-color-rgb));
  }
  .cl_secondary_color {
    color: rgb(var(--secondary-color-rgb));
  }

  // Background colors
  .cl_primary_background {
    background: rgb(var(--background-rgb));
  }
  .cl_secondary_background {
    background: rgb(var(--foreground-rgb));
  }

  // Border colors
  .cl_primary_border_color {
    border-color: rgb(var(--primary-color-rgb));
  }
  .cl_secondary_border_color {
    border-color: rgb(var(--foreground-rgb));
  }

  // Only for react-select component
  .cl_select {
    input {
      box-shadow: none !important;

      :focus {
        box-shadow: none !important;
      }
    }
  }

  // Scrollbar
  /* Vertical width */
  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  /* Horizontal height */
  ::-webkit-scrollbar:horizontal {
    height: 6px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 8px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #cfcfcf;
    border-radius: 8px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    // /* Background color */
    // --background-rgb: 0, 0, 0;
    // --foreground-rgb: 255, 255, 255;

    // /* Button/text colors */
    // --primary-color-rgb: 255, 167, 38;
    // --secondary-color-rgb: 255, 255, 255;

    // Text color
    // --text-primary-color-rgb: 255, 255, 255;

    // /* Supporting colors */
    // --green-color-rgb: 49, 103, 95;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--text-primary-color-rgb));
  background: rgb(var(--background-rgb));

  font-family: var(--primary-font-family);
  font-size: 14px;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  /* html {
    color-scheme: dark;
  } */
}
