// Input field
.cl_input_field {
    padding: 12px 16px;
    border: 1px solid rgba(var(--secondary-color-rgb), 0.2);
    border-radius: var(--border-radius);
    font-size: 1rem;
    font-weight: 400;
    width: 100%;
    outline: none;
    transition: 0.2s all ease-in-out;
}
.cl_input_field:hover {
    border: 1px solid rgba(var(--secondary-color-rgb), 0.4);
}
.cl_input_field:focus {
    border: 1px solid rgba(var(--primary-color-rgb), 0.8);
    box-shadow: 0px 0px 0px 3px rgba(var(--primary-color-rgb), 0.25);
}

.cl_input_custom_field {
    border: 1px solid rgba(var(--secondary-color-rgb), 0.2);
    border-radius: var(--border-radius);
    outline: none;
    transition: 0.2s all ease-in-out;
}
.cl_input_custom_field:hover {
    border: 1px solid rgba(var(--secondary-color-rgb), 0.4);
}
.cl_input_custom_field:focus {
    border: 1px solid rgba(var(--primary-color-rgb), 0.8);
    box-shadow: 0px 0px 0px 3px rgba(var(--primary-color-rgb), 0.25);
}

.radioContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.radioInput {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
}

.radioInput + label {
    position: relative;
    padding-left: 50px;
    display: inline-block;
    height: 24px;
    line-height: 24px;
    cursor: pointer;
}

.radioInput + label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 24px;
    background-color: #ccc;
    border-radius: 24px;
    transition: background-color 0.3s;
}

.radioInput + label::after {
    content: "";
    position: absolute;
    left: 4px;
    top: 4px;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.3s;
}

.radioInput:checked + label::before {
    background-color: rgba(var(--secondary-color-rgb), 0.4);
}

.radioInput:checked + label::after {
    transform: translateX(16px);
}

.toggleContainer {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 20px;
}

.toggleInput {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggleLabel {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 20px;
    background-color: #ccc;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.toggleInput:checked + .toggleLabel {
    background-color: #FFA726;
}

.toggleLabel::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.3s;
}

.toggleInput:checked + .toggleLabel::after {
    transform: translateX(20px);
}

