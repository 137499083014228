// Buttons
.cl_primary_btn {
    background-color: rgba(var(--primary-color-rgb), 1);
    color: rgb(var(--secondary-color-rgb));
    border: 1px solid rgba(var(--secondary-color-rgb), 1);
    transition: 0.2s all ease-in-out;
}
.cl_primary_btn:hover {
    filter: brightness(1.1);
}
.cl_primary_btn:focus {
    box-shadow: 0px 0px 0px 3px rgba(var(--primary-color-rgb), 0.25);
}

.cl_secondary_btn {
    background-color: transparent;
    color: rgb(var(--secondary-color-rgb));
    border: 1px solid rgba(var(--secondary-color-rgb), 1);
    transition: 0.2s all ease-in-out;
}
.cl_secondary_btn:hover {
    background-color: rgba(var(--secondary-color-rgb), 0.04);
}
.cl_secondary_btn:focus {
    box-shadow: 0px 0px 0px 3px rgba(var(--secondary-color-rgb), 0.25);
}
